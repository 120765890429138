$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');
        $('[data-nav]').slideToggle(200);
        $('#menu-label').text(($('#menu-label').text() === 'MENU')? 'CLOSE' : 'MENU');
    });

    $('[data-toggle]').on('click', function(){
        const target = $(this).data('toggle');
        $('[data-toggle-target=' + target + ']').slideToggle(200);
        return false;
    });

    if ($(window).width() < 768) {
        $('[data-toggle-sp]').on('click', function(){
            const target = $(this).data('toggle-sp');
            $('[data-toggle-sp-target=' + target + ']').slideToggle(100);
            $(this).toggleClass('changed');
            return false;
        });
    }

    
});
